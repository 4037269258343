import React, { useEffect, useState } from 'react';
import {
   Box,
   FormControlLabel,
   Switch,
   Table,
   TableBody,
   TableHead,
   TableCell,
   TableContainer,
   TableRow,
   Typography,
} from '@mui/material';
import CompanyRow from './companyRow';
import { useGetCompanies } from '../hooks/useGetAllCompanies';
import { CardSection } from '../../../layout';
import { useToggle } from '../../../../hooks';

const AllCompanyTable = () => {
   const [archived, setArchived] = useToggle(false);
   const companies = useGetCompanies({ archived });
   console.log(companies);

   return (
      <Box>
         <CardSection title="Filters">
            <Typography>Companies</Typography>
            <FormControlLabel
               control={<Switch checked={archived} onChange={setArchived} />}
               label="Archived"
            />
         </CardSection>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>Company Name</TableCell>
                     <TableCell>Last Accessed</TableCell>
                     <TableCell>Total Users</TableCell>
                     <TableCell>Features</TableCell>
                     <TableCell>Locations</TableCell>
                     <TableCell>Usage</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {companies.map((company) => (
                     <CompanyRow key={company.cid} company={company} />
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Box>
   );
};

export default AllCompanyTable;
