import { useEffect, useState } from 'react';
import { tasksRef } from '../../firebaseRefs';
export const useGetAllTasks = (status) => {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   useEffect(() => {
      let query = tasksRef(status);

      const unsubscribe = query.onSnapshot(
         (snapshot) => {
            if (!snapshot.empty) {
               let documents = [];
               snapshot.forEach((doc) => {
                  documents.push({ id: doc.id, ...doc.data() });
               });
               if (status === 'todo' || status === 'inprogress') {
                  sortAssending(documents);
               } else {
                  sortDesending(documents);
               }
               setData(documents);
               setLoading(false);
            } else {
               setData([]);
               setLoading(false);
            }
         },
         (error) => {
            setError(error);
            setLoading(false);
         },
      );
      return () => unsubscribe();
   }, [status]);
   return { data, loading, error };
};

function sortAssending(documents) {
   documents.sort((a, b) => {
      const dateA = new Date(a.dueDate.seconds * 1000 + a.dueDate.nanoseconds / 1000000);
      const dateB = new Date(b.dueDate.seconds * 1000 + b.dueDate.nanoseconds / 1000000);
      const yearA = dateA.getFullYear();
      const yearB = dateB.getFullYear();
      if (yearA !== yearB) return yearA - yearB;
      const monthA = dateA.getMonth();
      const monthB = dateB.getMonth();
      if (monthA !== monthB) return monthA - monthB;
      const dayA = dateA.getDate();
      const dayB = dateB.getDate();
      if (dayA !== dayB) return dayA - dayB;
      return a.order - b.order;
   });
}
function sortDesending(documents) {
   documents.sort((a, b) => {
      const dateA = new Date(
         a.completedDate.seconds * 1000 + a.completedDate.nanoseconds / 1000000,
      );
      const dateB = new Date(
         b.completedDate.seconds * 1000 + b.completedDate.nanoseconds / 1000000,
      );
      const yearA = dateA.getFullYear();
      const yearB = dateB.getFullYear();
      if (yearA !== yearB) return yearB - yearA;
      const monthA = dateA.getMonth();
      const monthB = dateB.getMonth();
      if (monthA !== monthB) return monthB - monthA;
      const dayA = dateA.getDate();
      const dayB = dateB.getDate();
      if (dayA !== dayB) return dayB - dayA;
      return a.order - b.order;
   });
}
