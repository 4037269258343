import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import AdminPage from '../Admin/Dash';
import { AppLayout, Footer, AdminAppLayout } from '../layout';

import AuthPage from '../Auth';
import DashboardPage from '../Dashboard';
import CRMPage from '../Admin/CRM';
import GlobalReportingPage from '../Admin/GlobalReporting';
import GlobalReportingUsagePage from '../Admin/GlobalReporting/pages/usage';
import GlobalUsage from '../Admin/SiteUsage';
import AdminNotifications from '../Admin/Notifications';
import KiosksPage from '../Kiosks';
import LogOutPage from '../logOut';
import MsTeamsAuthPage from '../MicrosoftTeams';
import PasswordForgetPage from '../PasswordForget';
import PeoplePage from '../People';
import PermissionDenied from '../PermissionDenied';
import ProfilePage from '../Profile';
import ReportingPage from '../Reporting';
import ScanInPage from '../ScanIn';
import SchedulePage from '../Schedule';
import PasswordSetPage from '../setPassword';
import SettingsPage from '../Settings';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import SupportPage from '../SupportPage';
import VisitorsPage from '../Visitors';
import VisitorDashPage from '../Visitors/VisitorDash';

import API from '../API';

import LegacyThemeProvider from '../LegacyThemeProvider';
import ThemeProvider from '../ThemeProvider';
import { makeStyles } from '@material-ui/core';

import * as microsoftTeams from '@microsoft/teams-js';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';
import TasksPage from '../Admin/Tasks';

const useStyles = makeStyles((theme) => ({
   msTeamsContent: {
      flexGrow: 1,
      padding: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
         marginLeft: theme.spacing(0),
      },
      [theme.breakpoints.up('sm')]: {
         marginLeft: theme.spacing(0),
      },
      [theme.breakpoints.down('sm')]: {
         padding: theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0),
      },
   },
}));

const App = () => {
   const classes = useStyles();
   microsoftTeams.initialize();
   var msTeams = false;
   var visitors = false;
   var kiosks = false;
   let itoadmin = false;
   if (
      window.location.hostname.includes('ngrok.io') ||
      window.location.hostname.includes('msteams')
      // ||
      // window.location.hostname.includes('staging-intheoffice')
   ) {
      msTeams = true;
   } else if (
      window.location.pathname.includes('itoadmin') ||
      window.location.hostname.includes('itoadmin') ||
      window.location.hostname.includes('localhost')
   ) {
      itoadmin = true;
   } else if (window.location.pathname.includes('visitors')) {
      visitors = true;
   } else if (window.location.pathname.includes('kiosks')) {
      kiosks = true;
   }

   return (
      <ThemeProvider>
         <LegacyThemeProvider>
            <Router>
               {msTeams ? (
                  <React.Fragment>
                     <main className={classes.msTeamsContent}>
                        <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
                        <Route path={ROUTES.AUTH} component={AuthPage} />
                        <Route path={ROUTES.MSTEAMSAUTH} component={MsTeamsAuthPage} />
                        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                        <Route path={ROUTES.SCAN_IN} component={ScanInPage} />
                        <Route path={ROUTES.LOGOUT} component={LogOutPage} />
                        <Route
                           path={ROUTES.PASSWORD_FORGET}
                           component={PasswordForgetPage}
                        />
                        <Route path={ROUTES.PASSWORD_SET} component={PasswordSetPage} />
                        <Route path={ROUTES.SCHEDULE} component={SchedulePage} />
                        <Route path={ROUTES.PEOPLE} component={PeoplePage} />
                        <Route path={ROUTES.PROFILE} component={ProfilePage} />
                        <Route path={ROUTES.SETTINGS} component={SettingsPage} />
                        <Route path={ROUTES.ADMIN} component={AdminPage} />
                        <Route path={ROUTES.SUPPORT} component={SupportPage} />
                        <Route path={ROUTES.VISITORDASH} component={VisitorDashPage} />
                        <Route
                           path={ROUTES.PERMISSIONDENIED}
                           component={PermissionDenied}
                        />
                     </main>
                  </React.Fragment>
               ) : itoadmin ? (
                  <AdminAppLayout>
                     <Route path={ROUTES.ADMIN} component={AdminPage} />
                     <Route
                        exact
                        path={ROUTES.GLOBALREPORTING}
                        component={GlobalReportingPage}
                     />
                     <Route
                        path={ROUTES.GLOBALREPORTINGUSAGE}
                        component={GlobalReportingUsagePage}
                     />
                     <Route path={ROUTES.CRM} component={CRMPage} />
                     <Route path={ROUTES.GLOBALUSAGE} component={GlobalUsage} />
                     <Route
                        path={ROUTES.ADMINNOTIFICATIONS}
                        component={AdminNotifications}
                     />
                     <Route path={ROUTES.TASKSPAGE} component={TasksPage} />
                     <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                     <Route path={ROUTES.LOGOUT} component={LogOutPage} />
                     <Route path={ROUTES.PERMISSIONDENIED} component={PermissionDenied} />
                  </AdminAppLayout>
               ) : visitors ? (
                  <React.Fragment>
                     <Route path={ROUTES.VISITORS} component={VisitorsPage} />
                  </React.Fragment>
               ) : kiosks ? (
                  <React.Fragment>
                     <Route path={ROUTES.KIOSKS} component={KiosksPage} />
                  </React.Fragment>
               ) : (
                  <AppLayout>
                     <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
                     <Route path={ROUTES.AUTH} component={AuthPage} />
                     <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                     <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                     <Route path={ROUTES.SCAN_IN} component={ScanInPage} />
                     <Route path={ROUTES.LOGOUT} component={LogOutPage} />
                     <Route
                        path={ROUTES.PASSWORD_FORGET}
                        component={PasswordForgetPage}
                     />
                     <Route path={ROUTES.PASSWORD_SET} component={PasswordSetPage} />
                     <Route path={ROUTES.SCHEDULE} component={SchedulePage} />
                     <Route path={ROUTES.PEOPLE} component={PeoplePage} />
                     <Route path={ROUTES.PROFILE} component={ProfilePage} />
                     <Route path={ROUTES.SETTINGS} component={SettingsPage} />
                     <Route path={ROUTES.ADMIN} component={AdminPage} />
                     <Route path={ROUTES.REPORTING} component={ReportingPage} />
                     <Route path={ROUTES.SUPPORT} component={SupportPage} />
                     <Route path={ROUTES.VISITORDASH} component={VisitorDashPage} />
                     <Route path={ROUTES.PERMISSIONDENIED} component={PermissionDenied} />
                     <Route path={ROUTES.API} component={API} />
                  </AppLayout>
               )}
            </Router>
         </LegacyThemeProvider>
      </ThemeProvider>
   );
};

const AppPage = compose(withFirebase)(App);

export default withAuthentication(AppPage);
