import firebase from 'firebase/app';

export const createBatch = () => firebase.firestore().batch();
export const increment = (i = 1) => firebase.firestore.FieldValue.increment(i);
export const deleteField = () => firebase.firestore.FieldValue.delete();
export const arrayUnion = (value) => firebase.firestore.FieldValue.arrayUnion(value);
export const arrayRemove = (value) => firebase.firestore.FieldValue.arrayRemove(value);

export const notificationQueue = (doc) =>
   firebase.firestore().doc(`notifications/${doc}`);

export const notificationQueueAllEmails = () =>
   firebase.firestore().collection(`notifications/queue/emails`);
export const notificationQueueEmailNewDoc = () =>
   firebase.firestore().collection(`notifications/queue/emails`).doc();
export const notificationQueueEmail = (doc) =>
   firebase.firestore().doc(`notifications/queue/emails/${doc}`);
