import React, { useState } from 'react';
import { notificationObject } from '../data';
import {
   Box,
   Button,
   Typography,
   TextField,
   IconButton,
   Select,
   FormControl,
   InputLabel,
   MenuItem,
   Grid,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useSaveNotification } from '../hooks/useSaveNotification';

const NewNotificationForm = ({ setNoteModalOpen, item = null }) => {
   const [form, setForm] = useState(() => {
      if (item !== null) {
      } else {
         return {
            ...notificationObject,
            sendTimestamp: new Date().toISOString().slice(0, 16),
         };
      }
   });
   const [customVariables, setCustomVariables] = useState([]);

   const handleCustomVariableChange = (index, e) => {
      const { name, value } = e.target;
      const newCustomVariables = [...customVariables];
      if (name == 'key') {
         newCustomVariables[index][name] = value.replace(/\s+/g, '_').toLowerCase();
      } else {
         newCustomVariables[index][name] = value;
      }
      setCustomVariables(newCustomVariables);
   };

   const addCustomVariable = () => {
      setCustomVariables([...customVariables, { key: '', value: '' }]);
   };

   const removeCustomVariable = (index) => {
      const newCustomVariables = customVariables.filter((_, i) => i !== index);
      setCustomVariables(newCustomVariables);
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({
         ...prevForm,
         [name]: value,
      }));
   };

   async function addToQueue(status = 'draft') {
      let formattedVariables = {};
      let formattedForm = { ...form };
      formattedForm.sendTimestamp = new Date(form.sendTimestamp);
      if (customVariables.length > 0) {
         customVariables.forEach((variable) => {
            formattedVariables[variable.key] = variable.value;
         });
      }
      let data = { ...formattedForm, customVariables: formattedVariables };
      try {
         await useSaveNotification(data);
         setForm({
            ...notificationObject,
            sendTimestamp: new Date().toISOString().slice(0, 16),
         });
         setNoteModalOpen(false);
      } catch (error) {
         console.error(error, 'error saving notification');
      }
   }

   return (
      <Box>
         <Typography variant="h6">New Notification</Typography>
         <TextField
            label="Type"
            name="type"
            value={form.type}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            helperText="Internal use only - used to identify emails from the queues"
         />
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <TextField
                  label="To"
                  name="to"
                  value={form.to}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
               />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormControl fullWidth margin="normal">
                  <InputLabel>From</InputLabel>
                  <Select
                     label="From"
                     name="from"
                     value={form.from}
                     onChange={handleChange}
                  >
                     <MenuItem value="intheOffice<noreply@intheoffice.io>">
                        noreply@intheoffice.io
                     </MenuItem>
                     <MenuItem value="intheOffice<hi@intheoffice.io>">
                        hi@intheoffice.io
                     </MenuItem>
                     <MenuItem value="Jon Kent<jon@intheoffice.io>">
                        jon@intheoffice.io
                     </MenuItem>
                     <MenuItem value="Adam Scorey<adam@intheoffice.io>">
                        adam@intheoffice.io
                     </MenuItem>
                  </Select>
               </FormControl>
            </Grid>
         </Grid>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <TextField
                  label="cc"
                  name="cc"
                  value={form.cc}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
               />
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextField
                  label="bcc"
                  name="bcc"
                  value={form.bcc}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
               />
            </Grid>
         </Grid>
         <TextField
            label="Email Template Code"
            name="emailTemplate"
            value={form.emailTemplate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="This is the code from SendGrid"
            required
         />
         <TextField
            label="UID"
            name="uid"
            value={form.uid}
            onChange={handleChange}
            fullWidth
            margin="normal"
         />
         <TextField
            label="Send Timestamp"
            name="sendTimestamp"
            type="datetime-local"
            value={form.sendTimestamp}
            onChange={handleChange}
            fullWidth
            margin="normal"
         />
         <Typography variant="h6" mt={2}>
            Custom Variables
         </Typography>
         {customVariables.length > 0 &&
            customVariables.map((variable, index) => (
               <Box key={index} display="flex" alignItems="center" mt={1}>
                  <TextField
                     label="Key"
                     name="key"
                     value={variable.key}
                     onChange={(e) => handleCustomVariableChange(index, e)}
                     margin="normal"
                     required
                  />
                  <TextField
                     label="Value"
                     name="value"
                     value={variable.value}
                     onChange={(e) => handleCustomVariableChange(index, e)}
                     margin="normal"
                     style={{ marginLeft: 8 }}
                     required
                  />
                  <IconButton
                     onClick={() => removeCustomVariable(index)}
                     color="secondary"
                  >
                     <RemoveCircleOutline />
                  </IconButton>
               </Box>
            ))}
         <Button
            variant="outlined"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={addCustomVariable}
            style={{ marginTop: 8 }}
         >
            Add Custom Variable
         </Button>
         <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" color="primary" onClick={() => addToQueue()}>
               Save as Draft
            </Button>
            <Button
               variant="contained"
               color="primary"
               onClick={() => addToQueue('pending')}
            >
               Add to queue
            </Button>
            <Button
               variant="outlined"
               color="secondary"
               onClick={() => setForm(notificationObject)}
            >
               Discard
            </Button>
         </Box>
      </Box>
   );
};

export default NewNotificationForm;
