import React from 'react';
import SideBarItem from './SideBarItem';
import { MAIN_AUTHENTICATED_ADMIN_ROUTES } from '../../../constants/routes';

const AdminNav = ({ authUser }) => {
   let shownRoutes = [];
   shownRoutes = Object.values(MAIN_AUTHENTICATED_ADMIN_ROUTES).filter((route, index) => {
      return route;
   });

   return (
      <>
         {shownRoutes.map((navItem, index) => (
            <SideBarItem key={index} navItem={navItem} />
         ))}
      </>
   );
};

export default AdminNav;
