import React from 'react';
import List from '@mui/material/List';
import NotificationListItem from './notificationListItem';

const NotificationList = ({ items, checked, setChecked, setNoteModalOpen }) => {
   return (
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
         {items.length > 0 &&
            items.map((item, key) => {
               return (
                  <NotificationListItem
                     items={items}
                     item={item}
                     key={key}
                     checked={checked}
                     setChecked={setChecked}
                     setNoteModalOpen={setNoteModalOpen}
                  />
               );
            })}
      </List>
   );
};

export default NotificationList;
