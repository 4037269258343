import { useEffect, useState } from 'react';
import { notificationQueue, notificationQueueAllEmails } from '../functions/firebaseRefs';
export const useGetNotificationsQueue = (args = 'queue') => {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   useEffect(() => {
      const unsubscribe = notificationQueueAllEmails(args)
         .orderBy('sendTimestamp')
         .onSnapshot(
            (snapshot) => {
               if (!snapshot.empty) {
                  let documents = [];
                  snapshot.forEach((doc) => {
                     documents.push({ id: doc.id, ...doc.data() });
                  });
                  setData(documents);
                  setLoading(false);
               } else {
                  setData([]);
                  setLoading(false);
               }
            },
            (error) => {
               setError(error);
               setLoading(false);
            },
         );
      return () => unsubscribe();
   }, [args]);
   return { data, loading, error };
};
