import { useEffect, useState } from 'react';
import { notificationQueue } from '../functions/firebaseRefs';
export const useGetQueue = (args = 'queue') => {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   useEffect(() => {
      const unsubscribe = notificationQueue(args).onSnapshot(
         (snapshot) => {
            if (snapshot.exists) {
               setData(snapshot.data());
               console.log(snapshot.data());
               setLoading(false);
            } else {
               setData([]);
               setLoading(false);
            }
         },
         (error) => {
            setError(error);
            setLoading(false);
         },
      );
      return () => unsubscribe();
   }, [args]);
   return { data, loading, error };
};
