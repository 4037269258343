import { useEffect, useState } from 'react';
import { taskOverview } from '../../firebaseRefs';
export const useGetTasksOverview = (args = 'queue') => {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   useEffect(() => {
      const unsubscribe = taskOverview().onSnapshot(
         (snapshot) => {
            console.log(snapshot);
            if (snapshot.exists) {
               setData(snapshot.data());
               setLoading(false);
            } else {
               setData(null);
               setLoading(false);
            }
         },
         (error) => {
            setError(error);
            setLoading(false);
         },
      );
      return () => unsubscribe();
   }, []);
   return { data, loading, error };
};
