export const notificationObject = {
   to: '',
   cc: '',
   bcc: '',
   from: 'intheOffice<noreply@intheoffice.io>',
   customVariables: {},
   status: 'pending',
   uid: '',
   emailTemplate: '',
   sendTimestamp: null,
   type: '',
};

export const stubNotifications = [
   {
      to: 'kenty26@gmail.com',
      cc: 'jon@intheoffice.io',
      bcc: null,
      from: 'noreply@intheoffice.io',
      customVariables: {
         firstName: 'JAnet',
      },
      status: 'pending',
      uid: '1',
      emailTemplate: 'GT-23523t623',
   },
   {
      to: 'kenty26+12@gmail.com',
      cc: null,
      bcc: null,
      from: 'noreply@intheoffice.io',
      customVariables: {
         firstName: 'Tony',
      },
      status: 'sent',
      uid: '2',
      emailTemplate: 'GT-23523t623',
   },
   {
      to: 'kenty26+12@gmail.com',
      cc: null,
      bcc: null,
      from: 'noreply@intheoffice.io',
      customVariables: {
         firstName: 'Tony',
      },
      status: 'paused',
      uid: '2',
      emailTemplate: 'GT-23523t623',
   },
   {
      to: 'kenty26+12@gmail.com',
      cc: null,
      bcc: null,
      from: 'noreply@intheoffice.io',
      customVariables: {
         firstName: 'Tony',
      },
      status: 'failed',
      uid: '2',
      emailTemplate: 'GT-23523t623',
   },
];
