import React, { useState } from 'react';

import { CardSection } from '../../../layout';
import { Box, Grid, Typography } from '@mui/material';
import { useGetTasksOverview } from '../hooks/useGetTasksOverview';
import Loading from '../../../Loading';
import { useAuth } from '../../../../providers';

const TasksHeader = ({ filters, setFilters }) => {
   const { loading, data, error } = useGetTasksOverview();
   const { authUser } = useAuth();
   return (
      <CardSection sx={{ mb: 1, boxShadow: 'none' }}>
         {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
               <Loading />
            </Box>
         ) : (
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                     To do: {data[authUser.uid].todo}
                  </Typography>
                  <Typography variant="body2">
                     In progress: {data[authUser.uid].inprogress}
                  </Typography>
                  <Typography variant="body2">
                     Completed: {data[authUser.uid].completed}
                  </Typography>
                  <Typography variant="body2">
                     Total tasks: {data[authUser.uid].total}
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={4}></Grid>
               <Grid item xs={12} sm={6} md={4}></Grid>
            </Grid>
         )}
      </CardSection>
   );
};

export default TasksHeader;
