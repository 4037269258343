import { features } from './features';
export const convertCompaniesToCRM = (companies) => {
   let newList = [];
   companies.map((company) => {
      let crmCompany = {
         companyName: company.companyName,
         features: company?.features || false,
         lastAccessed: company?.lastAccessed || null,
         totalPeople: company.totalPeople,
         subscription: company?.subscription?.plan || null,
         cid: company.cid,
         status: 'concern',
      };
      if (company?.subscription?.plan === 'premium') {
         let downgraded = true;
         Object.keys(features).map((feature) => {
            if (company.features[feature]) {
               downgraded = false;
            }
         });
         if (downgraded) {
            crmCompany.subscription = 'downgraded';
         }
      }

      if (
         !company.lastAccessed ||
         company.lastAccessed.toMillis() < new Date().getTime() - 5184000000
      ) {
         crmCompany.status = 'dormant';
      } else if (
         !company.lastAccessed ||
         company.lastAccessed.toMillis() < new Date().getTime() - 1209600000
      ) {
         crmCompany.status = 'atRisk';
      } else if (
         !company.lastAccessed ||
         company.lastAccessed.toMillis() < new Date().getTime() - 604800000
      ) {
         crmCompany.status = 'concern';
      }
      newList.push(crmCompany);
   });
   return newList;
};
