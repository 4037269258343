import {
   notificationQueue,
   createBatch,
   increment,
   notificationQueueEmail,
   notificationQueueEmailNewDoc,
} from '../functions/firebaseRefs';

export const useSaveNotification = async (data) => {
   const doc = notificationQueueEmailNewDoc();
   const batch = createBatch();
   batch.set(notificationQueue('queue'), { emails: increment(1) }, { merge: true });
   batch.set(notificationQueueEmail(doc.id), data, { merge: true });
   try {
      await batch.commit();
   } catch (error) {
      return error;
   }
};
