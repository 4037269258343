import React, { useEffect } from 'react';
import {
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Tooltip,
   useTheme,
} from '@mui/material';
//only use adminapplayout in the admin subdomain
// import { useAppBar } from '../AppLayout/AppLayout';
import { useAppBar } from '../AdminAppLayout/AdminAppLayout';
import { useHistory, useLocation } from 'react-router-dom';

function animatedObject(theme, animatedProperty) {
   return theme.transitions.create(animatedProperty, {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
   });
}

const SideBarItem = ({ navItem }) => {
   const history = useHistory();
   const location = useLocation();
   const selected = navItem.path === location.pathname;
   const theme = useTheme();
   const { menuOpen } = useAppBar();
   const { name, icon, path, isExternal } = navItem;
   const Icon = icon;

   useEffect(() => {}, [menuOpen]);
   const internalRoute = () => history.push(path);
   const externalRoute = () => (window.location.href = path);

   return (
      <Tooltip title={name} placement="right" arrow disableHoverListener={menuOpen}>
         <ListItem
            sx={{ display: 'block', p: 0 }}
            onClick={isExternal ? externalRoute : internalRoute}
         >
            <ListItemButton
               variant="contained"
               selected={selected}
               sx={{
                  minHeight: 48,
                  justifyContent: 'start',
                  px: [1.5, 2],
                  py: 2,
               }}
            >
               <ListItemIcon
                  sx={{
                     color: selected ? 'primary.light' : 'text',
                     minWidth: theme.spacing(4),
                     mr: menuOpen ? 2 : 0,
                     justifyContent: 'center',
                     transition: animatedObject(theme, 'margin-right'),
                  }}
               >
                  <Icon />
               </ListItemIcon>

               <ListItemText
                  primary={name}
                  sx={{
                     opacity: menuOpen ? '1' : '0',
                     transition: animatedObject(theme, 'opacity'),
                     m: 0,
                     width: 0,
                  }}
               />
            </ListItemButton>
         </ListItem>
      </Tooltip>
   );
};

export default SideBarItem;
