import React from 'react';
import { Box, Link, Paper, Typography } from '@mui/material';
import TaskStatusCheckbox from './taskStatusCheckbox';

const TasksIndividual = ({ task }) => {
   function formatDate(timestamp) {
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-GB');
   }
   const style = {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      textAlign: 'center',
   };
   function isPastDue(timestamp) {
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      let comparison = date < new Date();
      return comparison;
   }
   return (
      <Box component={Paper} elevation={0} sx={{ mb: 1, p: 1, width: '100%' }}>
         <Box sx={style}>
            <TaskStatusCheckbox task={task} />
            <Typography
               variant="body1"
               component={Link}
               onClick={() => alert('link')}
            >{`${task.title}`}</Typography>
         </Box>
         <Box sx={{ m: 1, p: 1, borderRadius: 1, backgroundColor: '#f1f1f1' }}>
            <Typography variant="body2" sx={{ fontSize: 'small' }}>
               {task.description}
            </Typography>
         </Box>
         <Box
            display="flex"
            flexDirection="row"
            sx={{ mt: 1, justifyContent: 'space-between' }}
         >
            <Box display="flex" flexDirection="column" sx={{ alignItems: 'center' }}>
               <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                  {task.status == 'todo' || task.status == 'inprogress'
                     ? 'Created:'
                     : 'Due:'}
               </Typography>
               <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                  {task.status == 'todo' || task.status == 'inprogress'
                     ? formatDate(task.created)
                     : formatDate(task.dueDate)}
               </Typography>
            </Box>
            <Box display="flex" flexDirection="column" sx={{ alignItems: 'center' }}>
               <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                  {task.status == 'todo' || task.status == 'inprogress'
                     ? 'Due:'
                     : 'Completed:'}
               </Typography>
               <Typography
                  variant="body2"
                  sx={{
                     fontSize: 'smaller',
                     color:
                        isPastDue(task.dueDate) &&
                        (task.status == 'todo' || task.status == 'inprogress')
                           ? '#D32F2F'
                           : '#000',
                  }}
               >
                  {task.status == 'todo' || task.status == 'inprogress'
                     ? formatDate(task.dueDate)
                     : formatDate(task.completedDate)}
               </Typography>
            </Box>
         </Box>
      </Box>
   );
};

export default TasksIndividual;
