import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Stack, Tooltip } from '@mui/material';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import DeleteIcon from '@mui/icons-material/Delete';
import NewNotificationForm from './newNotificationForm';
import { useGetQueue } from '../hooks/useGetQueue';
import firebase from 'firebase/app';
import { convertTimestampToReadableFormat } from '../utils/functions';

const Toolbar = ({ checked, items, noteModalOpen, setNoteModalOpen }) => {
   const info = useGetQueue();
   const [disabled, setDisabled] = useState(true);

   async function pauseItems() {
      console.log('pauseItems');
   }
   async function removeItems() {
      console.log('removeItems');
   }
   useEffect(() => {
      if (checked.length > 0) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
   }, [checked]);

   function openModal() {
      setNoteModalOpen(true);
   }

   function firebaseTimestamp(obj) {
      const time = new Date(obj.seconds * 1000);
      return time.toLocaleString();
   }
   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75vw',
      bgcolor: 'background.paper',
      p: 4,
   };

   async function testFunction() {
      const newTest = firebase
         .functions()
         .httpsCallable('testBillingEmail-testBillingEmail');
      try {
         const res = await newTest();
         console.log(res);
      } catch (error) {
         console.log(error);
      }
   }

   return (
      <Box
         sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            bgcolor: 'background.paper',
            mb: 2,
         }}
      >
         <Modal
            open={noteModalOpen}
            onClose={() => setNoteModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <NewNotificationForm setNoteModalOpen={setNoteModalOpen} />
            </Box>
         </Modal>
         <Box>
            Last Send: {!info.loading && firebaseTimestamp(info.data.lastProcessedTime)}
         </Box>
         <Box>
            Total: {items.length} | Selected: {checked.length}
         </Box>
         <Box>
            <Button variant="contained" onClick={openModal}>
               New Notification
            </Button>
            <Button variant="contained" onClick={testFunction}>
               Run test billing email
            </Button>
         </Box>
         {/* <Box>
            <Stack direction="row" spacing={2}>
               <Tooltip title="Pause" placement="top">
                  <IconButton
                     aria-label="pause"
                     onClick={() => pauseItems()}
                     disabled={disabled}
                  >
                     <PausePresentationIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip title="Remove from queue" placement="top">
                  <IconButton
                     aria-label="remove from queue"
                     onClick={() => removeItems()}
                     disabled={disabled}
                  >
                     <DeleteIcon />
                  </IconButton>
               </Tooltip>
            </Stack>
         </Box> */}
      </Box>
   );
};

export default Toolbar;
