import React from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InProgressIcon from './inProgressIcon';

const GetIcon = ({ status, hovered }) => {
   if (hovered) {
      return <TaskAltIcon />;
   }
   switch (status) {
      case 'completed':
         return <TaskAltIcon />;
      case 'inprogress':
         return <InProgressIcon />;
      case 'archive':
         return <InventoryIcon />;
      default:
         return <RadioButtonUncheckedIcon />;
   }
};

export default GetIcon;
