import React, { useEffect } from 'react';
import { Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import useGetRecentUsage from '../hooks/useGetRecentUsage';
import { features } from '../utils/features';
import LineChart from '../../SiteUsage/charts/lineChart';
import TimeProgress from '../../SiteUsage/company/timeProgress';
import { DateToday, getWeekDatesArray } from '../../../../constants/functions';
import { convertToDateName } from '../../../../constants/functions';

const CompanyRow = ({ company }) => {
   const [data, setData] = React.useState(null);
   const startDate = getWeekDatesArray(DateToday(-7))[0];
   const cid = company.cid;
   console.log(cid);
   const usage = cid !== null ? useGetRecentUsage({ cid, startDate }) : null;
   // console.log(usage);
   useEffect(() => {
      if (usage !== null) {
         let processedData = [];
         let totalUsers = [];
         let newUsers = 0;
         Object.entries(usage).forEach(([key, value]) => {
            let dailyUsers = 0;
            let appViews = 0;
            let teamsViews = 0;
            Object.entries(value).forEach(([uid, views]) => {
               if (uid !== 'totalPageViews') {
                  if (!totalUsers.includes(uid)) {
                     totalUsers.push(uid);
                     newUsers++;
                  }
                  dailyUsers++;

                  Object.entries(views).forEach(([time, page]) => {
                     if (time !== 'pageViews' && page.includes('msteams')) {
                        teamsViews++;
                     } else if (time !== 'pageViews' && page.includes('app')) {
                        appViews++;
                     }
                  });
               }
            });
            let average = Number(value.totalPageViews) / Number(dailyUsers);
            processedData.push({
               dateRef: key,
               name: convertToDateName(key),
               totalPageViews: value.totalPageViews,
               dailyUsers,
               appViews,
               teamsViews,
               average,
               newUsers,
            });
         });

         setData(processedData);
      }
   }, [usage]);
   function formatDate(date) {
      let time = date.toDate();
      let readable = time.toDateString();
      return readable;
   }
   const labels = [
      {
         dataKey: 'totalPageViews',
         name: 'Total Page Views',
      },
      {
         dataKey: 'dailyUsers',
         name: 'Daily Users',
      },
      {
         dataKey: 'appViews',
         name: 'App Views',
      },
      {
         dataKey: 'teamsViews',
         name: 'Teams Views',
      },
      {
         dataKey: 'average',
         name: 'Average',
      },
      {
         dataKey: 'newUsers',
         name: 'New Users',
      },
   ];

   return (
      <TableRow sx={{ maxWidth: '100px' }}>
         <TableCell sx={{ maxWidth: '100px' }}>
            {company?.subscription?.plan === 'premium' && (
               <WorkspacePremiumIcon color="warning" />
            )}{' '}
            {company.companyName}{' '}
         </TableCell>
         <TableCell>
            {company.lastAccessed !== undefined && (
               <span>{formatDate(company.lastAccessed)}</span>
            )}
         </TableCell>
         <TableCell>{company.totalPeople}</TableCell>
         <TableCell>
            <Stack direction="row" spacing={1}>
               {Object.entries(features).map(([key, value]) => {
                  if (company.features && company.features[key]) {
                     return (
                        <Tooltip key={key} title={key}>
                           {value}
                        </Tooltip>
                     );
                  }
               })}
            </Stack>
         </TableCell>
         <TableCell>
            <Stack direction="column">
               {company.locations &&
                  Object.entries(company.locations).map(([key, value]) => (
                     <span key={key}>{value}</span>
                  ))}
            </Stack>
         </TableCell>
         <TableCell>
            {data !== null && <LineChart data={data} labels={labels} />}
         </TableCell>
         {/* <TableCell>{bills[0]?.bill ? Number(bills[0].bill).toFixed(2) : ''}</TableCell>
         <TableCell>{bills[1]?.bill ? Number(bills[1].bill).toFixed(2) : ''}</TableCell>
         <TableCell>{bills[2]?.bill ? Number(bills[2].bill).toFixed(2) : ''}</TableCell> */}
      </TableRow>
   );
};
export default CompanyRow;
