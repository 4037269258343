import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { Box } from '@mui/material';
import AllCompanyTable from './components/allCompanyTable';

const CRMPage = () => {
   return (
      <Box sx={{ m: 2 }}>
         <AllCompanyTable />
      </Box>
   );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(withEmailVerification, withAuthorization(condition))(CRMPage);
