import {
   increment,
   createBatch,
   notificationQueue,
   notificationQueueEmail,
} from '../functions/firebaseRefs';

export const useDeleteNotification = async (key) => {
   const batch = createBatch();
   batch.delete(notificationQueueEmail(key));
   batch.set(notificationQueue('queue'), { emails: increment(-1) }, { merge: true });

   try {
      await batch.commit();
      return;
   } catch (error) {
      return error;
   }
};
