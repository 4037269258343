import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useGetRecentUsage = ({ cid, startDate }) => {
   const [usage, setUsage] = useState(null);

   useEffect(() => {
      if (!cid || !startDate) return;

      const fetchUsage = async () => {
         try {
            const results = await firebase
               .firestore()
               .collection(`usageLogs/companies/company/${cid}/dates`)
               .where(firebase.firestore.FieldPath.documentId(), '>=', startDate)
               .get();

            let data = {};
            results.forEach((doc) => {
               data[doc.id] = doc.data();
            });
            setUsage(data);
         } catch (error) {
            console.error('Error fetching usage data: ', error);
         }
      };

      fetchUsage();
   }, [cid, startDate]);

   return usage;
};

export default useGetRecentUsage;
