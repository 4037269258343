import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { Box } from '@mui/material';
import Main from './main';

const DashPage = () => {
   return (
      <Box>
         <Main />
      </Box>
   );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(withEmailVerification, withAuthorization(condition))(DashPage);
