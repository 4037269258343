import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PaidIcon from '@mui/icons-material/Paid';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import * as ROLES from './roles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// user management
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/logout';
export const LOGOUT = '/logout';
export const AUTH = '/auth';
export const LOGGEDINAUTH = '/auth?redirect=settings';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_SET = '/setpassword';

// main app routes
export const DASHBOARD = '/';
export const SCHEDULE = '/schedule';
export const PEOPLE = '/people';
export const REPORTING = '/reporting';
export const SETTINGS = '/settings';
export const PROFILE = '/profile';
export const VISITORDASH = `/visitordash`;

// deeplinking routes
export const SETTINGSUSERS = '/settings?tab=2';

// onsite user management
export const SCAN_IN = '/scanin';
export const KIOSKS = '/kiosks';
export const VISITORS = '/visitors';

// protected route management
export const PERMISSIONDENIED = '/403';

// admin routes
export const ADMIN = '/dash';
export const GLOBALREPORTING = '/globalreporting';
export const GLOBALREPORTINGUSAGE = '/globalreportingusage';
export const GLOBALUSAGE = '/globalusage';
export const CRM = '/crm';
export const ADMINNOTIFICATIONS = '/notifications';
export const TASKSPAGE = '/tasks';

//api routes
export const API = '/API/v1';

// help and information routes
export const SUPPORT = '/support';

// alt app routes
export const MSTEAMSAUTH = '/msTeamsAuth';

// Marketing site
export const MARKETING_SITE_HOME_PAGE = 'https://www.intheoffice.io';

export const MAIN_AUTHENTICATED_ROUTES = {
   ['DASHBOARD']: {
      path: DASHBOARD,
      name: 'Dashboard',
      alias: 'scheduling',
      icon: DashboardIcon,
      subMenu: false,
      accessLevel: [ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: true,
      externalPath: 'https://www.intheoffice.io/team-coordination',
   },
   ['SCHEDULE']: {
      path: SCHEDULE,
      name: 'Schedule',
      alias: 'scheduling',
      icon: DateRangeIcon,
      subMenu: false,
      accessLevel: [ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: true,
      externalPath: 'https://www.intheoffice.io/team-coordination',
   },

   ['VISITORDASH']: {
      path: VISITORDASH,
      name: 'Visitors',
      alias: 'visitors',
      icon: AssignmentIndIcon,
      subMenu: false,
      accessLevel: [ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: true,
      externalPath: 'https://www.intheoffice.io/visitor-management',
   },
   ['REPORTING']: {
      path: REPORTING,
      name: 'Reporting',
      alias: 'reporting',
      icon: BarChartIcon,
      subMenu: false,
      accessLevel: [ROLES.ADMIN],
      isExternal: false,
      dynamic: true,
      externalPath: null,
   },
   ['PEOPLE']: {
      path: PEOPLE,
      name: 'People',
      alias: 'people',
      icon: PeopleIcon,
      subMenu: false,
      accessLevel: [ROLES.ADMIN],
      isExternal: false,
      dynamic: false,
      externalPath: null,
   },
   ['SETTINGS']: {
      path: SETTINGS,
      name: 'Settings',
      alias: 'settings',
      icon: SettingsIcon,
      subMenu: false,
      accessLevel: [ROLES.ADMIN],
      isExternal: false,
      dynamic: false,
      externalPath: null,
   },
   // ['LEAVE']: {
   //    path: VISITORDASH,
   //    name: 'Leave Management',
   //    alias: 'leave',
   //    icon: AssignmentIndIcon,
   //    subMenu: false,
   //    accessLevel: [ROLES.STAFF, ROLES.ADMIN],
   //    isExternal: false,
   //    dynamic: true,
   // externalPath: null,
   // },
   // ['TIMESHEETS']: {
   //    path: VISITORDASH,
   //    name: 'Timesheets',
   //    alias: 'timesheets',
   //    icon: PunchClockIcon,
   //    subMenu: false,
   //    accessLevel: [ROLES.STAFF, ROLES.ADMIN],
   //    isExternal: false,
   //    dynamic: true,
   // externalPath: null,
   // },
};

export const MAIN_UNAUTHENTICATED_ROUTES = {
   ['HOME']: {
      path: MARKETING_SITE_HOME_PAGE,
      name: 'Back to website',
      alias: 'home',
      icon: HomeIcon,
      subMenu: false,
      accessLevel: [ROLES.PUBLIC, ROLES.STAFF, ROLES.ADMIN],
      isExternal: true,
      dynamic: false,
   },
   ['SIGN_IN']: {
      path: SIGN_IN,
      name: 'Sign in',
      alias: 'sign_in',
      icon: PersonIcon,
      subMenu: false,
      accessLevel: [ROLES.PUBLIC, ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: false,
   },
};

export const USER_PROFILE_MENU_ROUTES = {
   ['PROFILE']: {
      path: PROFILE,
      name: 'Profile',
      alias: 'profile',
      icon: PersonOutlineIcon,
      subMenu: false,
      accessLevel: [ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: false,
   },
   ['SIGN_OUT']: {
      path: SIGN_OUT,
      name: 'Sign out',
      alias: 'sign_out',
      icon: LogoutIcon,
      subMenu: false,
      accessLevel: [ROLES.STAFF, ROLES.ADMIN],
      isExternal: false,
      dynamic: false,
   },
};

export const MAIN_AUTHENTICATED_ADMIN_ROUTES = {
   ['Admin']: {
      path: ADMIN,
      name: 'Admin',
      alias: 'Admin',
      icon: DashboardIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
   ['CRM']: {
      path: CRM,
      name: 'CRM',
      alias: 'crm',
      icon: PermContactCalendarIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
   ['USAGE']: {
      path: GLOBALUSAGE,
      name: 'Usage',
      alias: 'usage',
      icon: AutoGraphIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
   ['INVOICING']: {
      path: GLOBALREPORTING,
      name: 'Invoicing',
      alias: 'invoicing',
      icon: PaidIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
   ['NOTIFICATIONS']: {
      path: ADMINNOTIFICATIONS,
      name: 'Notifications',
      alias: 'notifications',
      icon: EmailIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
   ['TASKS']: {
      path: TASKSPAGE,
      name: 'Tasks',
      alias: 'tasks',
      icon: TaskAltIcon,
      accessLevel: [ROLES.GLOBADMIN],
   },
};
