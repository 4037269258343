import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { convertCompaniesToCRM } from '../utils/convertCompaniesToCRM';

export const useGetCompanies = ({ archived = false }) => {
   const [companies, setCompanies] = useState([]);

   useEffect(() => {
      const fetchCompanies = async () => {
         const results = await firebase
            .firestore()
            .collection('company')
            .where('archived', '==', archived)
            .get();
         const lastAccessed = await firebase.firestore().doc(`usageLogs/companies`).get();

         let companiesList = [];
         results.forEach((doc) => {
            console.log(doc.data());
            let data = { cid: doc.id, ...doc.data() };
            if (lastAccessed.data()[data.cid]) {
               data.lastAccessed = lastAccessed.data()[data.cid];
            }
            companiesList.push(data);
         });
         companiesList = companiesList.sort((a, b) => {
            if (a.companyName > b.companyName) {
               return 1;
            } else if (a.companyName < b.companyName) {
               return -1;
            }
            return 0;
         });
         console.log(convertCompaniesToCRM(companiesList));
         setCompanies(companiesList);
      };

      fetchCompanies();
   }, [archived]);

   return companies;
};
