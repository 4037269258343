import React, { useState } from 'react';
import { Checkbox, SvgIcon, Popover, MenuItem, Box } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskStatus from './taskStatus';

import InProgressIcon from './inProgressIcon';
import GetIcon from './getIcon';
import { useUpdateTask } from '../hooks/useUpdateTask';

const TaskStatusCheckbox = ({ task, ...props }) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const [hovered, setHovered] = useState(false);

   const onStatusChange = async (newStatus) => {
      try {
         await useUpdateTask(task, 'status', newStatus);
      } catch (error) {
         console.warn('Error -', error);
      }
   };

   const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
      setHovered(true);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
      setHovered(false);
   };

   const handleStatusChange = (newStatus) => {
      onStatusChange(newStatus);
      handlePopoverClose();
   };

   const handleCheckboxClick = () => {
      onStatusChange(
         task.status === 'todo' || task.status === 'inprogress' ? 'completed' : 'todo',
      );
   };

   return (
      <Box
         onMouseEnter={handlePopoverOpen}
         onMouseLeave={handlePopoverClose}
         display="inline-flex"
         alignItems="center"
      >
         <Checkbox
            icon={<GetIcon status={task.status} hovered={hovered} />}
            checkedIcon={<TaskAltIcon />}
            indeterminateIcon={<InProgressIcon />}
            indeterminate={task.status === 'inprogress'}
            checked={task.status === 'completed'}
            onChange={handleCheckboxClick}
            sx={{
               '&.Mui-checked': {
                  color: '#4CAF50',
               },
            }}
            {...props}
         />
         <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableAutoFocus
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'left',
            }}
         >
            <MenuItem onClick={() => handleStatusChange('todo')}>To Do</MenuItem>
            <MenuItem onClick={() => handleStatusChange('inprogress')}>
               In Progress
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange('completed')}>Completed</MenuItem>
            <MenuItem onClick={() => handleStatusChange('archive')}>Archive</MenuItem>
         </Popover>
      </Box>
   );
};

export default TaskStatusCheckbox;
