import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import GroupsIcon from '@mui/icons-material/Groups';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const features = {
   scheduling: <AccessTimeIcon />,
   advancedScheduling: <MoreTimeIcon />,
   groups: <GroupsIcon />,
   multisite: <BusinessIcon />,
   spaceBookings: <EventSeatIcon />,
   visitors: <PermContactCalendarIcon />,
   reporting: <BarChartIcon />,
   timesheets: <PunchClockIcon />,
   leave: <BeachAccessIcon />,
};
