import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { useGetAllTasks } from '../hooks/useGetAllTasks';
import Loading from '../../../Loading';
import TasksIndividual from './tasksIndividual';
import { formatStatus } from '../helpers';

const TasksList = ({ status, filters, ...props }) => {
   const theme = useTheme();
   const { loading, data, error } = useGetAllTasks(status);
   return (
      <Box
         sx={{
            height: '65vh',
            border: '1px dashed grey',
            borderRadius: '4px',
            ml: 2,
            '& > *': {
               width: '70vw', // Max width on mobile
               [theme.breakpoints.up('sm')]: {
                  width: '325px', // Fixed width on larger screens
               },
            },
         }}
      >
         <Typography variant="h6" sx={{ p: 1 }}>
            {formatStatus(status)}
         </Typography>
         {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
               <Loading />
            </Box>
         ) : (
            <Box
               display="flex"
               flexDirection="column"
               sx={{ p: 1, overflowY: 'scroll', height: 'calc(100% - 48px)' }}
            >
               {!loading &&
                  data.length > 0 &&
                  data.map((task) => <TasksIndividual key={task.id} task={task} />)}
            </Box>
         )}
      </Box>
   );
};

export default TasksList;
