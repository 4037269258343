import firebase from 'firebase/app';
// import "firebase/compat/firestore";

export const createBatch = () => firebase.firestore().batch();
export const increment = (i = 1) => firebase.firestore.FieldValue.increment(i);
export const deleteField = () => firebase.firestore.FieldValue.delete();

export const allCompanies = () => firebase.firestore().collection('company');
export const getLocation = (lid) => firebase.firestore().doc(`location/${lid}`).get();
export const getUser = (cid, uid) =>
   firebase.firestore().doc(`company/${cid}/people/${uid}`).get();
export const taskOverview = () => firebase.firestore().doc(`crm/tasks`);
export const tasksRef = (status) =>
   firebase.firestore().collection(`crm/tasks/${status}`);
export const taskRef = (tid, status) =>
   firebase.firestore().doc(`crm/tasks/${status}/${tid}`);
