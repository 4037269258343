export const formatStatus = (status) => {
   switch (status) {
      case 'completed':
         return 'Completed';
      case 'inprogress':
         return 'In Progress';
      case 'pending':
         return 'Pending';
      case 'archive':
         return 'Archived';
      case 'todo':
         return 'To Do';
      default:
         return 'To Do';
   }
};
