import React, { useState, useEffect } from 'react';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useFirebase } from '../../../Firebase';
import firebase from 'firebase/app';
import { Box } from '@mui/material';
import Loading from '../../../Loading';
import TimeProgress from './timeProgress';
import { DateToday, getWeekDatesArray } from '../../../../constants/functions';
import UserTable from './userTable';
import useGetRecentUsage from '../../CRM/hooks/useGetRecentUsage';

const CompanyOverview = () => {
   const [getParams, setParams] = useSearchParams();
   const params = getParams();
   const cid = params.cid ? params.cid : null;
   const startDate = getWeekDatesArray(DateToday(-14))[0];
   const usage = useGetRecentUsage({ cid, startDate });

   return (
      <Box>
         {usage !== null ? (
            <Box>
               <Box>
                  <TimeProgress usage={usage} />
               </Box>
               <Box>
                  <UserTable cid={cid} usage={usage} startDate={startDate} />
               </Box>
            </Box>
         ) : (
            <Loading />
         )}
      </Box>
   );
};

export default CompanyOverview;
