import React, { useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { PageLayout } from '../../layout';

import TasksHeader from './components/tasksHeader';
import TaskList from './components/tasksList';
import { AuthProvider } from '../../../providers';
import { Box } from '@mui/material';

const statuses = ['todo', 'inprogress', 'completed', 'archive'];

const TasksPage = () => {
   const [filters, setFilters] = useState({ public: false, status: 'completed' });

   return (
      <AuthProvider>
         <PageLayout>
            <TasksHeader filters={filters} setFilters={setFilters} />
            <Box
               display="flex"
               flexDirection="row"
               sx={{
                  height: '65vh',
                  overflowX: 'auto',
               }}
            >
               {statuses.map((status) => (
                  <Box>
                     <TaskList key={status} filters={filters} status={status} />
                  </Box>
               ))}
            </Box>
         </PageLayout>
      </AuthProvider>
   );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(withEmailVerification, withAuthorization(condition))(TasksPage);
