import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { convertTimestampToReadableFormat } from '../utils/functions';

const NotificationItemDetails = ({ item }) => {
   const theme = useTheme();
   const style = {
      width: '100%',
      maxWidth: '25%',
      // borderRight: '1px solid',
      pl: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
   };
   return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
         <Box sx={style}>
            <Box>
               <Typography variant="caption" gutterBottom>
                  Type:{item.type}
               </Typography>
            </Box>
            <Box>
               <Typography variant="caption" gutterBottom>
                  To:{item.to}
               </Typography>
            </Box>
            <Box>
               <Typography variant="caption">uid:{item.uid}</Typography>
            </Box>
            <Box>
               <Typography variant="caption">from:{item.from}</Typography>
            </Box>
         </Box>
         <Box sx={style}>
            <Box>
               <Typography variant="caption">template:{item.emailTemplate}</Typography>
            </Box>
            {item.cc !== '' && (
               <Box>
                  <Typography variant="caption">cc:{item.cc}</Typography>
               </Box>
            )}
            {item.bcc !== '' && (
               <Box>
                  <Typography variant="caption">bcc:{item.bcc}</Typography>
               </Box>
            )}
            <Box>
               <Typography variant="caption">
                  {console.log(item)}
                  Scheduled:{convertTimestampToReadableFormat(item.sendTimestamp)}
               </Typography>
            </Box>
         </Box>
         <Box sx={style}>
            {Object.keys(item.customVariables).length > 0 && (
               <Box>
                  <Typography variant="caption">CustomFields:</Typography>
                  {Object.entries(item.customVariables).map(([key, value]) => (
                     <Box key={key}>
                        <Typography variant="caption">
                           {key}: {value}
                        </Typography>
                     </Box>
                  ))}
               </Box>
            )}
         </Box>
         <Box sx={{ ...style, borderRight: 'none' }}>
            {item.status === 'pending' ? (
               <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                  {item.status}
               </Typography>
            ) : item.status === 'sending' ? (
               <Typography variant="caption" sx={{ color: theme.palette.warning.dark }}>
                  {item.status}
               </Typography>
            ) : item.status === 'sent' ? (
               <Typography variant="caption" sx={{ color: theme.palette.success.dark }}>
                  {item.status}
               </Typography>
            ) : item.status === 'failed' ? (
               <Typography variant="caption" color="error" sx={{ fontWeight: 'bold' }}>
                  {item.status}
               </Typography>
            ) : (
               <Typography variant="caption">{item.status}</Typography>
            )}
         </Box>
      </Box>
   );
};

export default NotificationItemDetails;
