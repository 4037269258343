import { createBatch, increment, taskOverview, taskRef } from '../../firebaseRefs';

export const useUpdateTask = async (task, field, update) => {
   if (field === 'status') {
      const batch = createBatch();
      let newTask = { ...task };
      newTask.status = update;
      batch.delete(taskRef(task.id, task.status));
      if (update === 'completed') {
         newTask.completedDate = new Date();
      }
      if (update === 'archived') {
         newTask.archivedDate = new Date();
      }
      if (update === 'inprogress' || update === 'todo') {
         newTask.completedDate = null;
         newTask.archivedDate = null;
      }
      batch.set(taskRef(task.id, update), newTask);
      let overview = {};
      task.recipients.forEach((recipient) => {
         overview[recipient] = { [task.status]: increment(-1), [update]: increment(1) };
      });
      batch.set(taskOverview(), overview, { merge: true });
      try {
         await batch.commit();
         return { code: 200, status: 'success', error: null };
      } catch (error) {
         console.warn('Error -', error);
         return { code: 400, status: 'error', error };
      }
   }
};
