import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import NotificationItemDetails from './notificationItemDetails';
import { useDeleteNotification } from '../hooks/useDeleteNotification';

const NotificationListItem = ({
   items,
   item,
   arrayKey,
   checked,
   setChecked,
   setNoteModalOpen,
}) => {
   const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
   };
   const editItem = (id) => {
      setNoteModalOpen(true);
   };
   const removeItem = async (id) => {
      try {
         await useDeleteNotification(id);
      } catch (error) {
         console.error(error, 'error deleting notification');
      }
   };

   return (
      <ListItem
         key={item.id}
         secondaryAction={
            <Stack direction="row" spacing={2}>
               <IconButton onClick={() => editItem(item.id)}>
                  <EditIcon />
               </IconButton>
               <IconButton edge="end" aria-label="pause">
                  <PausePresentationIcon />
               </IconButton>
               <IconButton onClick={() => removeItem(item.id)}>
                  <DeleteIcon />
               </IconButton>
            </Stack>
         }
         disablePadding
      >
         <ListItemButton role={undefined} onClick={handleToggle(item.id)} dense>
            <ListItemIcon>
               <Checkbox
                  edge="start"
                  checked={checked.includes(item.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': item.uid }}
               />
            </ListItemIcon>
            <ListItemText
               id={item.uid}
               primary={<NotificationItemDetails item={item} />}
            />
         </ListItemButton>
      </ListItem>
   );
};

export default NotificationListItem;
