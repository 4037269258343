import React, { useState } from 'react';
import NotificationList from './components/notificationList';
import { stubNotifications } from './data';
import { PageLayout } from '../../layout';
import Toolbar from './components/toolbar';
import { useGetNotificationsQueue } from './hooks/useGetNotificationsQueue';
import { Box, Button } from '@mui/material';
import firebase from 'firebase/app';

const Main = () => {
   const items = useGetNotificationsQueue();
   const [checked, setChecked] = useState([]);
   const [noteModalOpen, setNoteModalOpen] = useState(false);

   const getWeekDates = (date) => {
      // TODO: if the day is a sunday then the schedule loads on the next week because the dayNUmiber goes to -1
      var dayNumber = date.weekDay;
      var d = new Date(date.year, date.month, date.day);
      if (dayNumber === 0) {
         d.setDate(d.getDate() - 7);
      } else {
         d.setDate(d.getDate() - dayNumber);
      }
      var weekDates = [];
      for (var i = 0; i < 7; i++) {
         weekDates[i] =
            d.getFullYear() +
            ('0' + d.getMonth()).slice(-2) +
            ('0' + d.getDate()).slice(-2);

         d.setDate(d.getDate() + 1);
      }
      weekDates[0] =
         d.getFullYear() + ('0' + d.getMonth()).slice(-2) + ('0' + d.getDate()).slice(-2);

      return weekDates;
   };
   const DateToday = (change = 7) => {
      const date = new Date();
      if (typeof change !== 'undefined') {
         date.setDate(date.getDate() + change);
      }
      const today = {
         day: date.getDate(),
         month: date.getMonth(),
         year: date.getFullYear(),
         weekDay: date.getDay(),
         ref:
            date.getFullYear() +
            ('0' + date.getMonth()).slice(-2) +
            ('0' + date.getDate()).slice(-2),
         fullDate: date,
      };
      return today;
   };

   async function buildQueue() {
      const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
      const usage = await firebase.firestore().doc(`usageLogs/users`).get();
      const usageKeys = Object.keys(usage.data());

      await firebase
         .firestore()
         .collection(`location`)

         .where('nextStatusNotificationTimestamp', '<', currentTimestamp)
         // .where('status', '==', 'pending')
         .get()
         .then((results) => {
            results.forEach(async (loc) => {
               const weekDates = getWeekDates(DateToday());
               let routines = null;
               if (loc.data()?.routine) {
                  await firebase
                     .firestore()
                     .doc(`company/${loc.data().cid}/indexes/routines`)
                     .get()
                     .then((response) => {
                        if (response.exists) {
                           routines = response.data();
                        }
                     });
               }
               console.log(routines, 'Routines');
               const schedule = await firebase
                  .firestore()
                  .collection(`location/${loc.id}/schedule`)
                  .where(firebase.firestore.FieldPath.documentId(), '>=', weekDates[1])
                  .where(firebase.firestore.FieldPath.documentId(), '<=', weekDates[0])
                  .get()
                  .then((results) => {
                     let data = {};
                     weekDates.forEach(async (day) => {
                        data[day] = null;
                     });
                     if (results.empty) {
                        return data;
                     } else {
                        results.forEach((doc) => {
                           console.log(doc.id);
                           data[doc.id] = doc.data();
                        });
                        return data;
                     }
                  })
                  .catch((error) => {
                     console.error(error);
                  });
               console.log(schedule, '<<SCHEDULE');
               const people = await firebase
                  .firestore()
                  .doc(`location/${loc.id}/indexes/people`)
                  .get()
                  .then((pplResults) => {
                     if (pplResults.exists) {
                        return pplResults.data();
                     } else {
                        return null;
                     }
                  });
               Object.entries(people).forEach(([key, value]) => {
                  if (usageKeys.includes(key)) {
                  }
               });

               let data = [];
               console.log({ ...loc.data(), id: loc.id });
            });
         })
         .catch((error) => {
            console.error(error);
         });
      // const process = firebase
      //    .functions()
      //    .httpsCallable('processEmailQueue-processEmailQueue');
      // try {
      //    const result = await process();
      //    console.log('Function response:', result);
      // } catch (error) {
      //    console.error(error, 'error sending emails');
      // }
   }
   return (
      <PageLayout>
         {console.log(items)}
         {items.loading ? (
            'loading'
         ) : (
            <Box>
               <Toolbar
                  checked={checked}
                  items={items.data}
                  noteModalOpen={noteModalOpen}
                  setNoteModalOpen={setNoteModalOpen}
               />
               <NotificationList
                  items={items.data}
                  checked={checked}
                  setChecked={setChecked}
                  setNoteModalOpen={setNoteModalOpen}
               />
               <Button onClick={() => buildQueue()}>Build Queue</Button>
            </Box>
         )}
      </PageLayout>
   );
};

export default Main;
